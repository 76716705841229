import store from "../redux/store";
import { showAlert } from "../redux/actions/appActions";

class AlertHelper {
	_actionDispatcher = (type, text) => {
		store.dispatch(showAlert(type, text, 5000));
	};

	success = (text) => {
		this._actionDispatcher("success", text);
	};

	error = (text) => {
		this._actionDispatcher("danger", text);
	};

	warn = (text) => {
		this._actionDispatcher("warning", text);
	};

	info = (text) => {
		this._actionDispatcher("primary", text);
	};
}

const alerter = new AlertHelper();

export { alerter };
