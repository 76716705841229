import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { closeAppBackground } from "../../redux/actions/appActions";

export class Background extends Component {
	static propTypes = {
		isBackgroundOpen: PropTypes.bool.isRequired,
	};

	onClick = (e) => {
		if (this.props.closeBackgroundOnClick) {
			this.props.closeAppBackground();
		}
	};

	render() {
		return (
			<div
				onClick={this.onClick}
				className={`custom-background-container ${
					this.props.isBackgroundOpen ? "" : "d-none"
				}`}
			></div>
		);
	}
}

const mapStateToProps = (state) => ({
	isBackgroundOpen: state.app.isBackgroundOpen,
	closeBackgroundOnClick: state.app.closeBackgroundOnClick,
});

export default connect(mapStateToProps, { closeAppBackground })(Background);
