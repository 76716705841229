import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { Badge, Collapse, Button } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Box } from "react-feather";

import routes from "../../routes/Sidebar";

import _ from "lodash";

import { hideSidebar } from "../../redux/actions/sidebarActions";

const SidebarCategory = withRouter(
	({
		name,
		badgeColor,
		badgeText,
		icon: Icon,
		isOpen,
		children,
		onClick,
		location,
		to,
	}) => {
		const getSidebarCategoryExpanded = (isOpen, to) => {
			var rootPathMatched = false;

			// Since every app route is prefaced by /app we need to filter it out from the array
			// as well as any empty strings.
			var decomposedCurrentPath = location.pathname
				.split("/")
				.filter((n) => n !== "" && n !== "app");
			var decomposedPath = to
				? to.split("/").filter((n) => n !== "" && n !== "app")
				: [-1];

			if (decomposedPath[0] === decomposedCurrentPath[0]) {
				rootPathMatched = true;
			}

			if (isOpen || rootPathMatched) {
				return true;
			} else {
				return false;
			}
		};

		return (
			<li className={"sidebar-item"}>
				<span
					data-toggle="collapse"
					className={
						"sidebar-link " +
						(getSidebarCategoryExpanded(isOpen, to)
							? ""
							: "collapsed")
					}
					onClick={onClick}
					aria-expanded={
						getSidebarCategoryExpanded(isOpen, to)
							? "true"
							: "false"
					}
				>
					<Icon size={18} className="align-middle mr-3" />
					<span className="align-middle">{name}</span>
					{badgeColor && badgeText ? (
						<Badge
							color={badgeColor}
							size={18}
							className="sidebar-badge"
						>
							{badgeText}
						</Badge>
					) : null}
				</span>
				<Collapse isOpen={getSidebarCategoryExpanded(isOpen, to)}>
					<ul id="item" className={"sidebar-dropdown list-unstyled"}>
						{children}
					</ul>
				</Collapse>
			</li>
		);
	}
);

const SidebarItem = withRouter(
	({
		name,
		badgeColor,
		badgeText,
		isChildRoute,
		icon: Icon,
		location,
		to,
		onClick,
	}) => {
		const getSidebarItemClass = (path) => {
			var rootPathMatched = false;

			// Since every app route is prefaced by /app we need to filter it out from the array
			// as well as any empty strings.
			var decomposedCurrentPath = location.pathname
				.split("/")
				.filter((n) => n !== "" && n !== "app");
			var decomposedPath = path
				? path.split("/").filter((n) => n !== "" && n !== "app")
				: [-1];

			if (decomposedPath[0] === decomposedCurrentPath[0]) {
				rootPathMatched = true;
			}

			if (isChildRoute) {
				if (decomposedPath[1] !== decomposedCurrentPath[1]) {
					rootPathMatched = false;
				}
			}

			return location.pathname === path || rootPathMatched
				? "active"
				: "";
		};

		return (
			<li className={"sidebar-item " + getSidebarItemClass(to)}>
				<NavLink
					to={to}
					className="sidebar-link"
					activeClassName="active"
					onClick={onClick}
				>
					<div className={`${isChildRoute ? "ml-3" : ""}`}>
						{Icon ? (
							<Icon size={18} className="align-middle mr-3" />
						) : null}
						{name}
						{badgeColor && badgeText ? (
							<Badge
								color={badgeColor}
								size={18}
								className="sidebar-badge"
							>
								{badgeText}
							</Badge>
						) : null}
					</div>
				</NavLink>
			</li>
		);
	}
);

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	closeSidebar = () => {
		if (this.props.isMobile) {
			this.props.hideSidebar();
		}
	};

	toggle = (index) => {
		// Collapse all elements
		Object.keys(this.state).forEach(
			(item) =>
				this.state[index] ||
				this.setState(() => ({
					[item]: false,
				}))
		);

		// Toggle selected element
		this.setState((state) => ({
			[index]: !state[index],
		}));
	};

	evaluateRoutes = (routes) => {
		// Evaluate the access functions for all child routes
		routes.forEach((route) => {
			if (_.has(route, "children")) {
				route.children.forEach((child) => {
					var res = child.access();
					child.accessible = res;
				});
			}
		});

		/* Determine if any categories should be ommited 
    	if none of their children can be accessed */
		routes.forEach((route) => {
			// If route has no children then always show
			if (!_.has(route, "children")) {
				route.accessible = true;
			} else {
				var atLeastOneChildAccessible = route.children.some((child) => {
					return child.accessible === true;
				});

				if (atLeastOneChildAccessible) {
					route.accessible = true;
				} else {
					route.accessible = false;
				}
			}
		});
	};

	componentDidMount() {
		/* Open collapse element that matches current url */
		const pathName = this.props.location.pathname;

		routes.forEach((route, index) => {
			const isActive = pathName.indexOf(route.path) === 0;
			const isOpen = route.open;
			const isHome =
				route.containsHome && pathName === "/" ? true : false;

			this.setState(() => ({
				[index]: isActive || isOpen || isHome,
			}));
		});
	}

	render() {
		// Evaluate routes before render
		this.evaluateRoutes(routes);
		return (
			<nav
				/* className={
					"sidebar sidebar-always-available" +
					(!this.props.isOpen ? " toggled" : "") 
				} */
				className={
					"sidebar sidebar-always-available" +
					(this.props.isMobile
						? this.props.isOpen
							? " toggled"
							: " collapsed"
						: !this.props.isOpen
						? " toggled"
						: "") +
					(this.props.isSticky ? " sidebar-sticky" : "")
				}
			>
				<div className="sidebar-content">
					<PerfectScrollbar>
						<a className="sidebar-brand" href="/">
							<Box
								className="align-middle text-primary"
								size={24}
							/>{" "}
							<span className="align-middle">Family App</span>
						</a>

						<ul className="sidebar-nav">
							{routes.map((category, index) => {
								if (category.accessible) {
									return (
										<React.Fragment key={index}>
											{category.header ? (
												<li className="sidebar-header">
													{category.header}
												</li>
											) : null}

											{category.children ? (
												<SidebarCategory
													name={category.name}
													badgeColor={
														category.badgeColor
													}
													badgeText={
														category.badgeText
													}
													icon={category.icon}
													to={category.path}
													isOpen={this.state[index]}
													onClick={() =>
														this.toggle(index)
													}
												>
													{category.children.map(
														(route, index) => {
															if (
																route.accessible
															) {
																return (
																	<SidebarItem
																		key={
																			index
																		}
																		name={
																			route.name
																		}
																		to={
																			route.path
																		}
																		badgeColor={
																			route.badgeColor
																		}
																		badgeText={
																			route.badgeText
																		}
																		isChildRoute
																		onClick={
																			this
																				.closeSidebar
																		}
																	/>
																);
															} else {
																return null;
															}
														}
													)}
												</SidebarCategory>
											) : (
												<SidebarItem
													name={category.name}
													to={category.path}
													icon={category.icon}
													badgeColor={
														category.badgeColor
													}
													badgeText={
														category.badgeText
													}
													onClick={this.closeSidebar}
												/>
											)}
										</React.Fragment>
									);
								} else {
									return null;
								}
							})}
						</ul>
						{this.props.isMobile ? (
							<div className={"sidebar-item mb-3"}>
								<NavLink
									to="/"
									className="sidebar-link"
									activeClassName="active"
								>
									<Button color="danger">Logout</Button>
								</NavLink>
							</div>
						) : null}
					</PerfectScrollbar>
				</div>
			</nav>
		);
	}
}

const mapStateToProps = (state) => ({
	isOpen: state.sidebar.isOpen,
	isSticky: state.sidebar.isSticky,
	isMobile: state.app.isMobile,
});

export default withRouter(connect(mapStateToProps, { hideSidebar })(Sidebar));
