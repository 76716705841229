import { schema } from "normalizr";

// Define an Agent Schema
const agentSchema = new schema.Entity("agents");

// Define a Document Schema
const documentSchema = new schema.Entity("documents");

// Define a Picture Schema
const pictureSchema = new schema.Entity("pictures");

// Define a Real Estate Schema
const realestateSchema = new schema.Entity("realestates", {
	agents: [agentSchema],
	documents: [documentSchema],
	pictures: [pictureSchema],
});

export { agentSchema, pictureSchema, documentSchema, realestateSchema };
