import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import {
	USER_LOADED,
	USER_LOADING,
	AUTH_ERROR,
	SAVE_USER_AUTH_STATUS,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAILURE,
	LOGOUT_USER_SUCCESS,
} from "../constants";

import { isProductionEnvironment } from "../../utilities/helpers";

// Get token and auth state from local storage
var storedToken = localStorage.getItem("token");
var storedUserId = localStorage.getItem("userid");
var storedAuthStatus = null;

if (storedToken !== null) {
	storedAuthStatus = true;
} else {
	storedAuthStatus = false;
}

const initialState = {
	token: storedToken,
	isAuthenticated: storedAuthStatus,
	isLoading: false,
	firstname: null,
	lastname: null,
	userid: storedUserId,
	email: null,
	emailValidated: false,
	is_staff: false,
};

const initialStateDEV = {
	token: null,
	isAuthenticated: true,
	isLoading: false,
	firstname: "Emmanuel",
	lastname: "Okeke",
	userid: storedUserId,
	email: null,
	emailValidated: false,
	is_staff: true,
};

const returnInitialState = () => {
	if (isProductionEnvironment()) {
		return initialState;
	} else {
		return initialStateDEV;
	}
};

const AuthReducer = (state = returnInitialState(), action) => {
	switch (action.type) {
		case USER_LOADING:
			return {
				...state,
				isLoading: true,
			};

		case USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				firstname: action.payload.firstname,
				lastname: action.payload.lastname,
				userid: action.payload.id,
				email: action.payload.email,
				is_staff: action.payload.is_staff,
			};
		case LOGIN_USER_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				token: action.payload.token,
				firstname: action.payload.user.firstname,
				lastname: action.payload.user.lastname,
				userid: action.payload.user.id,
				email: action.payload.user.email,
				is_staff: action.payload.is_staff,
			};

		case SAVE_USER_AUTH_STATUS:
			localStorage.setItem("token", action.payload.token);
			localStorage.setItem("userid", action.payload.id);
			return state;

		case AUTH_ERROR:
		case LOGOUT_USER_SUCCESS:
		case LOGIN_USER_FAILURE:
			localStorage.removeItem("token");
			localStorage.removeItem("userid");
			return {
				...returnInitialState(),
			};

		default:
			return state;
	}
};

const persistConfig = {
	key: "auth",
	storage: storage,
	whitelist: ["userid", "token"],
	stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, AuthReducer);
