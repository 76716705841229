export function toggleTheme(name) {
	detachStylesheets();
	insertStylesheet(name);
}

function detachStylesheets() {
	Array.from(document.querySelectorAll('link[rel="stylesheet"]')).forEach(
		(style) => {
			style.parentNode.removeChild(style);
		}
	);
}

function insertStylesheet(name) {
	var link = document.createElement("link");
	link.href = process.env.REACT_APP_STATIC_URL + "/css/" + name + ".css";
	link.type = "text/css";
	link.rel = "stylesheet";
	document.getElementsByTagName("head")[0].appendChild(link);
}
