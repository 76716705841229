import async from "../components/Async";

import {
	Settings as SettingsIcon,
	Sliders as SlidersIcon,
	Users as UsersIcon,
	User as UserIcon,
	Users as AdminIcon,
	MapPin as RealEstateIcon,
} from "react-feather";

// Landing
import LandingPage from "../pages/landing/LandingPage";

// Admin acccess check
import { userIsAdmin } from "../utilities/access";

// Auth
const LoginPage = async(() => import("../pages/auth/LoginPage"));
const ResetPasswordPage = async(() =>
	import("../pages/auth/ResetPasswordPage")
);
const RequestResetPasswordPage = async(() =>
	import("../pages/auth/RequestResetPasswordPage")
);
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards
const HomePage = async(() => import("../pages/home/HomePage"));

// Profile
const Profile = async(() => import("../toolkit/pages/Profile"));

//Settings
const ProfileSettingsPage = async(() =>
	import("../pages/settings/ProfileSettingsPage")
);
const EmailSettingsPage = async(() =>
	import("../pages/settings/EmailSettingsPage")
);
const PasswordSettingsPage = async(() =>
	import("../pages/settings/PasswordSettingsPage")
);
const NotificationSettingsPage = async(() =>
	import("../pages/settings/NotificationSettingsPage")
);
const DeleteAccountSettingsPage = async(() =>
	import("../pages/settings/DeleteAccountSettingsPage")
);

// Real Estate
const ViewRealEstatePage = async(() =>
	import("../pages/real_estate/ViewRealEstatePage")
);
const AddRealEstatePage = async(() =>
	import("../pages/real_estate/AddRealEstatePage")
);
const EditRealEstatePage = async(() =>
	import("../pages/real_estate/EditRealEstatePage")
);
const RealEstateSummaryPage = async(() =>
	import("../pages/real_estate/RealEstateSummaryPage")
);
const RealEstateDetailsPage = async(() =>
	import("../pages/real_estate/RealEstateDetailsPage")
);

// Admin
const AddNewUserPage = async(() => import("../pages/admin/AddNewUserPage"));
const ViewUsersPage = async(() => import("../pages/admin/ViewUsersPage"));
const UserDetailsPage = async(() => import("../pages/admin/UserDetailsPage"));

// Misc
const EmailVerifyPage = async(() => import("../pages/misc/EmailVerifyPage"));
const NotificationsPage = async(() =>
	import("../pages/misc/NotificationsPage")
);

// Routes
const landingRoutes = {
	path: "/",
	name: "Landing Page",
	component: LandingPage,
	children: null,
};

const dashboardRoutes = {
	path: "/app",
	name: "Home",
	header: "Pages",
	icon: SlidersIcon,
	containsHome: true,
	privateRoute: true,
	component: HomePage,
};

const profileRoutes = {
	path: "/app/profile",
	name: "Profile",
	icon: UserIcon,
	privateRoute: true,
	component: Profile,
};

const settingsRoutes = {
	path: "/app/settings",
	name: "Settings",
	icon: SettingsIcon,
	children: [
		{
			path: "/app/settings/profile",
			name: "Profile Settings",
			component: ProfileSettingsPage,
			childPrivateRoute: true,
		},
		{
			path: "/app/settings/email",
			name: "Email Settings",
			component: EmailSettingsPage,
			childPrivateRoute: true,
		},
		{
			path: "/app/settings/password",
			name: "Password Settings",
			component: PasswordSettingsPage,
			childPrivateRoute: true,
		},
		{
			path: "/app/settings/notifications",
			name: "Notification Settings",
			component: NotificationSettingsPage,
			childPrivateRoute: true,
		},
		{
			path: "/app/settings/delete-account",
			name: "Delete Account Settings",
			component: DeleteAccountSettingsPage,
			childPrivateRoute: true,
		},
	],
};

const realEstateRoutes = {
	path: "/app/realestate",
	name: "Real Estate",
	icon: RealEstateIcon,
	children: [
		{
			path: "/app/realestate/view",
			name: "All Real Estate",
			component: ViewRealEstatePage,
			childPrivateRoute: true,
			access: () => {
				return true;
			},
		},
		{
			path: "/app/realestate/add",
			name: "Add Real Estate",
			component: AddRealEstatePage,
			childPrivateRoute: true,
			access: () => {
				return true;
			},
		},
		{
			path: "/app/realestate/summary",
			name: "Real Estate Summary",
			component: RealEstateSummaryPage,
			childPrivateRoute: true,
			access: () => {
				return true;
			},
		},
		{
			path: "/app/realestate/view/:id/details",
			name: "Real Estate Details",
			component: RealEstateDetailsPage,
			childPrivateRoute: true,
			access: () => {
				return true;
			},
		},
		{
			path: "/app/realestate/view/:id/edit",
			name: "Edit Real Estate",
			component: EditRealEstatePage,
			childPrivateRoute: true,
			access: () => {
				return true;
			},
		},
	],
};

const adminRoutes = {
	path: "/app/admin",
	name: "Admin",
	icon: AdminIcon,
	children: [
		{
			path: "/app/admin/users/add",
			name: "Add New User",
			component: AddNewUserPage,
			childPrivateRoute: true,
			access: () => {
				return userIsAdmin();
			},
		},
		{
			path: "/app/admin/users/view",
			name: "View Users",
			component: ViewUsersPage,
			childPrivateRoute: true,
			access: () => {
				return userIsAdmin();
			},
		},
		{
			path: "/app/admin/users/view/:id/details",
			name: "User Detail",
			component: UserDetailsPage,
			childPrivateRoute: true,
			access: () => {
				return userIsAdmin();
			},
		},
	],
};

const authRoutes = {
	path: "/app/auth",
	name: "Auth",
	icon: UsersIcon,
	badgeColor: "secondary",
	badgeText: "Special",
	children: [
		{
			path: "/app/login",
			name: "Login",
			component: LoginPage,
		},
		{
			path: "/app/password/reset/:resetId",
			name: "Reset Password",
			component: ResetPasswordPage,
		},
		{
			path: "/app/password/request-reset",
			name: "Request Password Reset",
			component: RequestResetPasswordPage,
		},
		{
			path: "/404",
			name: "404 Page",
			component: Page404,
		},
		{
			path: "/500",
			name: "500 Page",
			component: Page500,
		},
	],
};

const miscRoutes = {
	children: [
		{
			path: "/app/verify/:verificationId",
			name: "Email Verification",
			component: EmailVerifyPage,
			childPrivateRoute: true,
		},
		{
			path: "/app/notifications",
			name: "All Notifications",
			component: NotificationsPage,
			childPrivateRoute: true,
		},
	],
};

// Dashboard specific routes
export const dashboard = [
	dashboardRoutes,
	profileRoutes,
	adminRoutes,
	settingsRoutes,
	realEstateRoutes,
	miscRoutes,
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [];
