import {
	APP_LOADED,
	APP_LOADING,
	APP_BACKGROUND_OPEN,
	APP_BACKGROUND_CLOSED,
	SHOW_ALERT,
	DISMISS_ALERT,
	CLEAR_APP_STATE,
	WINDOW_SIZE_CHANGED,
	APP_CLOSED,
} from "../constants";

import { toastr } from "react-redux-toastr";

export const appLoading = (timeout = 5, message = false) => (
	dispatch,
	getState
) => {
	dispatch({
		type: APP_LOADING,
		payload: null,
	});

	/* Set up a timeout to dispatch the appLoaded action just in case the 
    user has slow internet */

	setTimeout(() => {
		dispatch(appLoaded());
		if (message) {
			toastr.error(
				"Timed Out",
				"The action could not be completed in time"
			);
		}
	}, timeout * 1000);
};

export const appLoaded = () => (dispatch, getState) => {
	dispatch({
		type: APP_LOADED,
		payload: null,
	});
};

export const openAppBackground = (
	closeOnClick = false,
	callbackOnClose = null
) => (dispatch, getState) => {
	dispatch({
		type: APP_BACKGROUND_OPEN,
		payload: {
			closeOnClick,
		},
	});
};

export const closeAppBackground = () => (dispatch, getState) => {
	dispatch({
		type: APP_BACKGROUND_CLOSED,
		payload: null,
	});
};

export const showAlert = (type, text, timeout) => (dispatch, getState) => {
	dispatch({
		type: SHOW_ALERT,
		payload: { type, text, timeout },
	});
};

export const dismissAlert = (id) => (dispatch, getState) => {
	dispatch({
		type: DISMISS_ALERT,
		payload: { id },
	});
};

export const clearAppState = () => (dispatch, getState) => {
	dispatch({
		type: CLEAR_APP_STATE,
		payload: null,
	});
};

export const setWindowSize = (size) => (dispatch, getState) => {
	dispatch({
		type: WINDOW_SIZE_CHANGED,
		payload: { size },
	});
};

export const appClosed = () => (dispatch, getState) => {
	dispatch({
		type: APP_CLOSED,
	});
};
