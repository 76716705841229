import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import {
	Row,
	Col,
	Button,
	ListGroupItem,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ListGroup,
} from "reactstrap";

import { AlertCircle, Bell, Home, UserPlus } from "react-feather";

import _ from "lodash";

export class NavbarNotifications extends Component {
	constructor(props) {
		super(props);

		this.state = {
			unseenNotifications: this.seenNotifications(),
			dropdownOpen: false,
		};
	}

	seenNotifications = () => {
		var seen = this.props.notifications.filter((obj) => {
			return obj.seen === false;
		});

		return seen;
	};

	toggle = () => {
		var newState = !this.state.dropdownOpen;

		this.setState({
			dropdownOpen: newState,
		});
	};

	render() {
		return (
			<Dropdown
				nav
				inNavbar
				className="mr-2 nav-notifications"
				isOpen={this.state.dropdownOpen}
				toggle={this.toggle}
			>
				{this.props.isMobile ? (
					<Link
						to="/app/notifications"
						className="nav-icon dropdown-toggle"
					>
						<div className="position-relative">
							<Bell className="align-middle" size={18} />
							<span className="indicator">
								{this.state.unseenNotifications.length}
							</span>
						</div>
					</Link>
				) : (
					<DropdownToggle nav className="nav-icon dropdown-toggle">
						<div className="position-relative">
							<Bell className="align-middle" size={18} />
							<span className="indicator">
								{this.state.unseenNotifications.length}
							</span>
						</div>
					</DropdownToggle>
				)}
				<DropdownMenu
					right
					className="dropdown-menu-lg py-0 nav-notifications-menu"
				>
					<div className="dropdown-menu-header position-relative">
						{this.state.unseenNotifications.length}{" "}
						{"New Notifications"}
					</div>
					<ListGroup>
						{this.state.unseenNotifications
							.slice(0, 4)
							.map((item, key) => {
								let icon = (
									<Bell size={18} className="text-warning" />
								);

								if (item.type === "important") {
									icon = (
										<AlertCircle
											size={18}
											className="text-danger"
										/>
									);
								}

								if (item.type === "login") {
									icon = (
										<Home
											size={18}
											className="text-primary"
										/>
									);
								}

								if (item.type === "request") {
									icon = (
										<UserPlus
											size={18}
											className="text-success"
										/>
									);
								}

								return (
									<ListGroupItem key={key}>
										<Row
											noGutters
											className="align-items-center"
										>
											<Col xs={2}>{icon}</Col>
											<Col xs={10} className={"pl-2"}>
												<div className="text-dark">
													{item.title}
												</div>
												<div className="text-muted small mt-1 d-block">
													{_.truncate(
														item.description,
														{ length: 60 }
													)}
												</div>
												<div className="text-muted small mt-1">
													{item.time.toLocaleString(
														"en-US",
														{
															dateStyle: "medium",
															timeStyle: "medium",
														}
													)}
												</div>
											</Col>
										</Row>
									</ListGroupItem>
								);
							})}
					</ListGroup>
					<DropdownItem header className="dropdown-menu-footer">
						<Link to="/app/notifications">
							<span className="text-muted">
								<Button
									color={
										this.state.unseenNotifications.length >
										0
											? "primary"
											: "secondary"
									}
									onClick={this.toggle}
								>
									see more notifications
								</Button>
							</span>
						</Link>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
}

const mapStateToProps = (state) => ({
	notifications: state.app.notifications,
	isMobile: state.app.isMobile,
});

export default connect(mapStateToProps)(NavbarNotifications);
