// App
export const APP_LOADING = "APP_LOADING";
export const APP_LOADED = "APP_LOADED";
export const APP_CLOSED = "APP_CLOSED";
export const APP_BACKGROUND_OPEN = "APP_BACKGROUND_OPEN";
export const APP_BACKGROUND_CLOSED = "APP_BACKGROUND_CLOSED";
export const SET_LIGHT_MODE = "SET_LIGHT_MODE";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const CLEAR_APP_STATE = "CLEAR_APP_STATE";
export const WINDOW_SIZE_CHANGED = "WINDOW_SIZE_CHANGED";
// Alerts
export const SHOW_ALERT = "SHOW_ALERT";
export const DISMISS_ALERT = "DISMISS_ALERT";

// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// Auth
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const REGISTER_USER_CLEANUP = "REGISTER_USER_CLEANUP";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const SAVE_USER_AUTH_STATUS = "SAVE_USER_AUTH_STATUS";

// Profile
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

// Settngs
export const BASIC_INFO_UPDATE_SUCCESS = "BASIC_INFO_UPDATE_SUCCESS";
export const BASIC_INFO_UPDATE_FAILURE = "BASIC_INFO_UPDATE_FAILURE";
export const PROFILE_INFO_UPDATE_SUCCESS = "PROFILE_INFO_UPDATE_SUCCESS";
export const PROFILE_INFO_UPDATE_FAILURE = "PROFILE_INFO_UPDATE_FAILURE";
export const EMAIL_UPDATE_SUCCESS = "EMAIL_UPDATE_SUCCESS";
export const EMAIL_UPDATE_FAILURE = "EMAIL_UPDATE_FAILURE";

// Permissions
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAILURE = "GET_PERMISSIONS_FAILURE";

// Real Estate
export const GET_REAL_ESTATE_SUCCESS = "GET_REAL_ESTATE_SUCCESS";
export const GET_REAL_ESTATE_FAILURE = "GET_REAL_ESTATE_FAILURE";
