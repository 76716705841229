import {
	SIDEBAR_VISIBILITY_TOGGLE,
	SIDEBAR_VISIBILITY_SHOW,
	SIDEBAR_STICKY_TOGGLE,
	SIDEBAR_STICKY_DISABLE,
	SIDEBAR_STICKY_ENABLE,
	SIDEBAR_VISIBILITY_HIDE,
} from "../constants";

export function toggleSidebar() {
	return {
		type: SIDEBAR_VISIBILITY_TOGGLE,
	};
}

export function showSidebar() {
	return {
		type: SIDEBAR_VISIBILITY_SHOW,
	};
}

export function hideSidebar() {
	return {
		type: SIDEBAR_VISIBILITY_HIDE,
	};
}

export function toggleStickySidebar() {
	return {
		type: SIDEBAR_STICKY_TOGGLE,
	};
}

export function enableStickySidebar() {
	return {
		type: SIDEBAR_STICKY_ENABLE,
	};
}

export function disableStickySidebar() {
	return {
		type: SIDEBAR_STICKY_DISABLE,
	};
}
