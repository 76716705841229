import {
	APP_LOADED,
	APP_LOADING,
	APP_BACKGROUND_OPEN,
	APP_BACKGROUND_CLOSED,
	SHOW_ALERT,
	DISMISS_ALERT,
	WINDOW_SIZE_CHANGED,
} from "../constants";

import { isProductionEnvironment } from "../../utilities/helpers";
import { isMobileView } from "../../utilities/helpers";
import _ from "lodash";

import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const initialState = {
	isLoading: false,
	isBackgroundOpen: false,
	closeBackgroundOnClick: false,
	alerts: [],
	notifications: [],
	currentAlertId: 0,
	isMobile: false,
};

const initialStateDEV = {
	isLoading: false,
	isBackgroundOpen: false,
	closeBackgroundOnClick: false,
	alerts: [],
	notifications: [
		{
			title: "Test",
			type: "request",
			description: "Request Succeeded",
			time: new Date("March 5, 2021 13:24:00"),
			seen: false,
		},
		{
			title: "Test",
			type: "request",
			description: "The PDF file has been downloaded",
			time: new Date("March 5, 2021 11:24:00"),
			seen: false,
		},
		{
			title: "Long Test",
			type: "request",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus id finibus ante. Aliquam feugiat mi nisl. In hac habitasse platea dictumst. Aenean interdum elementum risus eget tristique. In nec pulvinar risus",
			time: new Date("December 8, 2020 03:24:00"),
			seen: false,
		},
	],
	currentAlertId: 0,
	isMobile: false,
};

const returnInitialState = () => {
	if (isProductionEnvironment()) {
		return initialState;
	} else {
		return initialStateDEV;
	}
};

const appReducer = (state = returnInitialState(), action) => {
	switch (action.type) {
		case WINDOW_SIZE_CHANGED:
			var mobile = isMobileView(action.payload.size);

			return {
				...state,
				isMobile: mobile,
			};
		case APP_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case APP_LOADED:
			return {
				...state,
				isLoading: returnInitialState().isLoading,
			};
		case APP_BACKGROUND_OPEN:
			return {
				...state,
				isBackgroundOpen: true,
				closeBackgroundOnClick: action.payloadcloseOnClick,
			};
		case APP_BACKGROUND_CLOSED:
			return {
				...state,
				isBackgroundOpen: returnInitialState().isLoading,
				closeBackgroundOnClick: returnInitialState()
					.closeBackgroundOnClick,
			};
		case SHOW_ALERT:
			const lastAlertId = state.currentAlertId;

			var newAlert = {
				id: lastAlertId + 1,
				type: action.payload.type,
				text: action.payload.text,
				timeout: action.payload.timeout,
			};
			var newAlertsShow = _.concat(state.alerts, newAlert);

			// Sort array by id
			newAlertsShow.sort((first, second) => {
				if (first.id < second.id) {
					return -1;
				} else if (first.id > second.id) {
					return 1;
				} else {
					return 0;
				}
			});

			return {
				...state,
				alerts: newAlertsShow,
				currentAlertId: lastAlertId + 1,
			};

		case DISMISS_ALERT:
			var newAlertsDismiss = _.differenceWith(
				state.alerts,
				[{ id: action.payload.id }],
				(arrVal, othVal) => {
					if (arrVal.id === othVal.id) {
						return true;
					} else {
						return false;
					}
				}
			);

			// Sort array by id
			newAlertsDismiss.sort((first, second) => {
				if (first.id < second.id) {
					return -1;
				} else if (first.id > second.id) {
					return 1;
				} else {
					return 0;
				}
			});

			return {
				...state,
				alerts: newAlertsDismiss,
			};

		default:
			return state;
	}
};

const persistConfig = {
	key: "app",
	storage: storage,
	whitelist: ["isMobile"],
	stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, appReducer);
