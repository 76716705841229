import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { THEME_TOGGLE } from "../constants";

const themes = {
	light: {
		primary: "#2c7be5",
		secondary: "#9d7bd8",
		tertiary: "#5997eb",
		success: "#4caf50",
		info: "#47bac1",
		warning: "#ff9800",
		danger: "#e51c23",
	},
	dark: {
		primary: "#3A89FF",
		secondary: "#495057",
		tertiary: "#0069fc",
		success: "#4bbf73",
		info: "#1f9bcf",
		warning: "#f0ad4e",
		danger: "#d9534f",
	},
};

const initialState = {
	currentTheme: themes.light,
	themes: themes,
	darkMode: false,
};

const themeReducer = (state = initialState, actions) => {
	switch (actions.type) {
		case THEME_TOGGLE:
			if (actions.payload === "light") {
				return {
					...state,
					currentTheme: state.themes.light,
					darkMode: false,
				};
			} else if (actions.payload === "dark") {
				return {
					...state,
					currentTheme: state.themes.dark,
					darkMode: true,
				};
			}
			break;
		default:
			return state;
	}
};

const persistConfig = {
	key: "theme",
	storage: storage,
	whitelist: ["darkMode", "currentTheme"],
	stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, themeReducer);
