import React, { Component } from "react";

import { connect } from "react-redux";

import Routes from "./routes/Routes";
import Alert from "./components/Alert";

import { ThemeProvider, createGlobalStyle } from "styled-components";

import { setWindowSize, appClosed } from "./redux/actions/appActions";
import { showSidebar, hideSidebar } from "./redux/actions/sidebarActions";

const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
`;

export class Main extends Component {
	componentDidMount() {
		if (this.props.isMobile) {
			this.props.hideSidebar();
		} else {
			this.props.showSidebar();
		}

		window.addEventListener("resize", this.checkWindowSize);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isMobile !== this.props.isMobile) {
			if (this.props.isMobile) {
				this.props.hideSidebar();
			} else {
				this.props.showSidebar();
			}
		}
	}

	checkWindowSize = () => {
		this.props.setWindowSize(window.innerWidth);
	};

	componentWillUnmount() {
		window.removeEventListener("resize", this.checkWindowSize);
	}

	render() {
		return (
			<ThemeProvider theme={this.props.currrentTheme}>
				<GlobalStyles />
				<Routes />
				<Alert />
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	currrentTheme: state.theme.currentTheme,
	isMobile: state.app.isMobile,
});

export default connect(mapStateToProps, {
	setWindowSize,
	showSidebar,
	hideSidebar,
	appClosed,
})(Main);
