import { GET_REAL_ESTATE_SUCCESS, GET_REAL_ESTATE_FAILURE } from "../constants";

import { isProductionEnvironment } from "../../utilities/helpers";

import { realestateSchema } from "../normalizers/realEstateNormalizer";
import { normalize } from "normalizr";

const initialState = {
	realestate: {},
	agents: {},
	pictures: {},
	documents: {},
};

const initialStateDEV = {
	realestate: {
		"8abc7167-1e7e-4bb0-84bb-7b117625c06e": {
			id: "8abc7167-1e7e-4bb0-84bb-7b117625c06e",
			address: "31200 FM 2920",
			address2: "Apt 222",
			city: "Waller",
			state: "Texas",
			zipcode: "77484",
			country: "United States of America",
			added_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			added_date: "2021-04-04",
			longitude: "0.000000",
			latitude: "0.000000",
			built_up: true,
			rented_out: false,
			name: "Property Test",
			size: 12,
			size_unit: "Acres",
			purchase_price: "1000000.00",
			purchase_price_currency: "NGN",
			current_value: "5000000.00",
			last_assessed_date: "2021-03-16",
			last_updated_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			last_updated_date: "2021-04-04",
			agents: [],
			documents: [],
			pictures: [],
		},
		"8cf80c71-4778-4d97-bdd1-9bdd09e167b9": {
			id: "8cf80c71-4778-4d97-bdd1-9bdd09e167b9",
			address: "31200 FM 2920",
			address2: "Apt 222",
			city: "Waller",
			state: "Texas",
			zipcode: "77484",
			country: "United States of America",
			added_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			added_date: "2021-04-04",
			longitude: "0.000000",
			latitude: "0.000000",
			built_up: false,
			rented_out: false,
			name: "Property Test",
			size: 12,
			size_unit: "Acres",
			purchase_price: "1000000.00",
			purchase_price_currency: "NGN",
			current_value: "5000000.00",
			last_assessed_date: "2021-03-16",
			last_updated_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			last_updated_date: "2021-04-04",
			agents: [],
			documents: [],
			pictures: [],
		},
		"250a65ea-c2d2-4c20-9e2a-a85ebfde062a": {
			id: "250a65ea-c2d2-4c20-9e2a-a85ebfde062a",
			address: "31200 FM 2920",
			address2: "Apt 222",
			city: "Waller",
			state: "Texas",
			zipcode: "77484",
			country: "United States of America",
			added_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			added_date: "2021-04-04",
			longitude: "0.000000",
			latitude: "0.000000",
			built_up: false,
			rented_out: false,
			name: "Property Test",
			size: 12,
			size_unit: "Acres",
			purchase_price: "1000000.00",
			purchase_price_currency: "NGN",
			current_value: "5000000.00",
			last_assessed_date: "2021-03-16",
			last_updated_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			last_updated_date: "2021-04-04",
			agents: ["3c487007-8d42-4321-9549-c3b3e4e64f32"],
			documents: [],
			pictures: [],
		},
		"960897cc-6e31-48cc-98d6-6da547496b2a": {
			id: "960897cc-6e31-48cc-98d6-6da547496b2a",
			address: "31200 FM 2920",
			address2: "Apt 222",
			city: "Waller",
			state: "Texas",
			zipcode: "77484",
			country: "United States of America",
			added_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			added_date: "2021-04-04",
			longitude: "0.000000",
			latitude: "0.000000",
			built_up: false,
			rented_out: false,
			name: "Property Test 22",
			size: 15,
			size_unit: "Acres",
			purchase_price: "1000.00",
			purchase_price_currency: "NGN",
			current_value: "5000000.00",
			last_assessed_date: "2021-03-09",
			last_updated_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			last_updated_date: "2021-04-04",
			agents: ["6d209f25-09c8-4d91-8dce-424a8573a089"],
			documents: [],
			pictures: [],
		},
		"0711e388-e726-4559-83d9-538f33b8e441": {
			id: "0711e388-e726-4559-83d9-538f33b8e441",
			address: "31200 FM 2920",
			address2: "Apt 222",
			city: "Waller",
			state: "Texas",
			zipcode: "77484",
			country: "United States of America",
			added_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			added_date: "2021-04-04",
			longitude: "0.000000",
			latitude: "0.000000",
			built_up: false,
			rented_out: false,
			name: "Property Test 22",
			size: 15,
			size_unit: "Acres",
			purchase_price: "1000.00",
			purchase_price_currency: "NGN",
			current_value: "5000000.00",
			last_assessed_date: "2021-03-09",
			last_updated_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			last_updated_date: "2021-04-04",
			agents: ["df323fab-897d-4de4-b984-27a493e48a30"],
			documents: [],
			pictures: [],
		},
		"e2322bc2-76c2-4d0f-bd28-cdd18d1799d8": {
			id: "e2322bc2-76c2-4d0f-bd28-cdd18d1799d8",
			address: "31200 FM 2920",
			address2: "Apt 222",
			city: "Waller",
			state: "Texas",
			zipcode: "77484",
			country: "United States of America",
			added_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			added_date: "2021-04-04",
			longitude: "0.000000",
			latitude: "0.000000",
			built_up: false,
			rented_out: false,
			name: "Property Test 22",
			size: 15,
			size_unit: "Acres",
			purchase_price: "1000.00",
			purchase_price_currency: "NGN",
			current_value: "5000000.00",
			last_assessed_date: "2021-03-09",
			last_updated_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			last_updated_date: "2021-04-04",
			agents: ["c986c113-c544-4905-bf39-c9c102d3ca77"],
			documents: ["71d7e2fa-21e0-4df0-9861-72dd8ec19001"],
			pictures: [],
		},
	},
	agents: {
		"3c487007-8d42-4321-9549-c3b3e4e64f32": {
			id: "3c487007-8d42-4321-9549-c3b3e4e64f32",
			agent_type: "LA",
			name: "Jude Agbanu",
			business: false,
			contact_firstname: "Jude",
			contact_lastname: "Agbanu",
			contact_phone: "5129945833",
			contact_email: "fbfbfb@gmail.com",
			address: "31200 FM 2920",
			address2: "Apt 222",
			city: "Waller",
			state: "Texas",
			zipcode: "77484",
			country: "United States of America",
			added_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			added_date: "2021-04-04",
			last_updated_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			last_updated_date: "2021-04-04",
		},
		"6d209f25-09c8-4d91-8dce-424a8573a089": {
			id: "6d209f25-09c8-4d91-8dce-424a8573a089",
			agent_type: "LA",
			name: "Jude Agbanu",
			business: false,
			contact_firstname: "Jude",
			contact_lastname: "Agbanu",
			contact_phone: "5129945833",
			contact_email: "fbfbfb@gmail.com",
			address: "31200 FM 2920",
			address2: "Apt 222",
			city: "Waller",
			state: "Texas",
			zipcode: "77484",
			country: "United States of America",
			added_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			added_date: "2021-04-04",
			last_updated_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			last_updated_date: "2021-04-04",
		},
		"df323fab-897d-4de4-b984-27a493e48a30": {
			id: "df323fab-897d-4de4-b984-27a493e48a30",
			agent_type: "LA",
			name: "Jude Agbanu",
			business: false,
			contact_firstname: "Jude",
			contact_lastname: "Agbanu",
			contact_phone: "5129945833",
			contact_email: "fbfbfb@gmail.com",
			address: "31200 FM 2920",
			address2: "Apt 222",
			city: "Waller",
			state: "Texas",
			zipcode: "77484",
			country: "United States of America",
			added_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			added_date: "2021-04-04",
			last_updated_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			last_updated_date: "2021-04-04",
		},
		"c986c113-c544-4905-bf39-c9c102d3ca77": {
			id: "c986c113-c544-4905-bf39-c9c102d3ca77",
			agent_type: "LA",
			name: "Jude Agbanu",
			business: false,
			contact_firstname: "Jude",
			contact_lastname: "Agbanu",
			contact_phone: "5129945833",
			contact_email: "fbfbfb@gmail.com",
			address: "31200 FM 2920",
			address2: "Apt 222",
			city: "Waller",
			state: "Texas",
			zipcode: "77484",
			country: "United States of America",
			added_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			added_date: "2021-04-04",
			last_updated_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			last_updated_date: "2021-04-04",
		},
	},
	documents: {
		"71d7e2fa-21e0-4df0-9861-72dd8ec19001": {
			id: "71d7e2fa-21e0-4df0-9861-72dd8ec19001",
			name: "Deed",
			uploaded_by: "d6cbfb50-c047-4348-bc57-4900f272b307",
			uploaded_date: "2021-04-04",
			stored_document:
				"http://localhost:8000/media/91d63f6c-466e-4285-9ece-07a40cfc40f8/documents/g-1145.pdf",
		},
	},
	pictures: {},
};

const returnInitialState = () => {
	if (isProductionEnvironment()) {
		return initialState;
	} else {
		return initialStateDEV;
	}
};

export default function (state = returnInitialState(), action) {
	switch (action.type) {
		case GET_REAL_ESTATE_SUCCESS:
			var normalizedRealEstate = normalize(action.payload, [
				realestateSchema,
			]);
			return {
				...state,
				realestate: normalizedRealEstate.entities.realestates,
				agents: normalizedRealEstate.entities.agents,
				pictures: normalizedRealEstate.entities.pictures,
				documents: normalizedRealEstate.entities.documents,
			};

		case GET_REAL_ESTATE_FAILURE:
		default:
			return state;
	}
}
