import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "../reducers/index";
import { persistStore } from "redux-persist";
import { isProductionEnvironment } from "../../utilities/helpers";
const initialState = {};

const middleware = [thunk];

function returnStore() {
	if (isProductionEnvironment()) {
		return createStore(
			rootReducer,
			initialState,
			applyMiddleware(...middleware)
		);
	} else {
		return createStore(
			rootReducer,
			initialState,
			composeWithDevTools(applyMiddleware(...middleware))
		);
	}
}

const store = returnStore();

const persistor = persistStore(store);

export { persistor };

export default store;
