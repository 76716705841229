import React, { Component } from "react";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./redux/store";

import store from "./redux/store";

import { loadUser } from "./redux/actions/authActions";
import { getProfile } from "./redux/actions/profileActions";
import { setWindowSize } from "./redux/actions/appActions";

import Main from "./Main";

import { loadCurrentTheme } from "./utilities/helpers";

export class App extends Component {
	constructor(props) {
		super(props);

		this.onBeforeLift = this.onBeforeLift.bind(this);
	}

	onBeforeLift = () => {
		store.dispatch(loadUser());
		store.dispatch(getProfile());
		loadCurrentTheme();
		store.dispatch(setWindowSize(window.innerWidth));
	};

	render() {
		return (
			<Provider store={store}>
				<PersistGate
					onBeforeLift={this.onBeforeLift}
					persistor={persistor}
				>
					<Main />
				</PersistGate>
			</Provider>
		);
	}
}

export default App;
