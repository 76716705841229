import React from "react";

import PermissionDenied from "../components/PermissionDenied";

export default (WrappedComponent, Layout) => {
	return class extends React.Component {
		constructor(props) {
			super(props);

			if (props.checkAccess) {
				var res = props.checkAccess();
			}

			this.state = {
				accessAllowed: res === false ? false : true,
			};
		}

		render() {
			if (this.state.accessAllowed === false) {
				return (
					<Layout>
						<PermissionDenied />
					</Layout>
				);
			} else {
				return (
					<Layout>
						<WrappedComponent {...this.props.passThroughProps} />
					</Layout>
				);
			}
		}
	};
};
