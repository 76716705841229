import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";

export class Footer extends Component {
	static propTypes = {};

	render() {
		return (
			<footer className="footer footer-always-available">
				<Container fluid>
					<Row className="text-muted">
						<Col xs="6" className="text-left">
							<ul className="list-inline">
								<li className="list-inline-item">
									<span className="text-muted" href="#">
										{"Private & Confidential"}
									</span>
								</li>
								<li className="list-inline-item ml-3">
									<span className="text-muted" href="#">
										Developed and Maintained by Chuma
									</span>
								</li>
							</ul>
						</Col>
						<Col xs="6" className="text-right">
							<p className="mb-0">
								&copy; {new Date().getFullYear()} -{" "}
								<span href="/" className="text-muted">
									Family App
								</span>
							</p>
						</Col>
					</Row>
				</Container>
			</footer>
		);
	}
}

export default Footer;
