import store from "../redux/store";
import { getPermissions } from "../redux/actions/permissionActions";

/**
 * This is a function that refreshes the users
 * permissions if they are older than 1 hour
 * @return {null}
 */

function refreshPermissions() {
	var lastUpdated = store.getState().permissions.lastUpdated;

	var diff = Date.now() - lastUpdated;
	const oneHour = 60 * 60 * 1000;

	if (diff > oneHour) {
		store.dispatch(getPermissions());
	}
}

/**
 * This is a function that checks if a user
 * has the required permissions
 * @param {string} permissionLevel
 * @return {boolean}
 */

function userHasPermissionLevel(permissionLevel) {
	refreshPermissions();

	if (permissionLevel === null) {
		return true;
	}

	switch (permissionLevel) {
		case "admin":
			return true;
		case "edit":
			return false;
		case "view":
			return false;
		default:
			return false;
	}
}

/**
 * This is a function that checks if a user
 * has the required permissions to access
 * a particular object
 * @param {string} objectId
 * @param {string} permissionLevel
 * @return {boolean}
 */

function userHasObjectPermission(objectId, permissionLevel) {
	refreshPermissions();

	if (permissionLevel === null) {
		return true;
	}

	if (objectId === null) {
		return false;
	}

	return true;
}

/**
 * This is a function that checks if a user
 * is an admin
 * @return {boolean}
 */

function userIsAdmin() {
	return store.getState().auth.is_staff;
}

export { userIsAdmin, userHasPermissionLevel, userHasObjectPermission };
