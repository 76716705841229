import {
	Home as HomeIcon,
	User as UserIcon,
	Users as AdminIcon,
	MapPin as RealEstateIcon,
} from "react-feather";

/* 
    Routes Top level routes(categories) are always visble
    but the child routes must define an access level. use
    null if the child route must be shown for all users.  
*/
const sidebarRoutes = [
	{
		path: "/app",
		name: "Home",
		icon: HomeIcon,
	},
	{
		name: "Real Estate",
		icon: RealEstateIcon,
		path: "/app/realestate/view",
	},
	{
		name: "Admin",
		icon: AdminIcon,
		path: "/app/admin/users/view",
	},
	{
		name: "Settings",
		icon: UserIcon,
		path: "/app/settings/profile",
	},
];

// All routes
export default sidebarRoutes;
