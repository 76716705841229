import { LOGOUT_USER_SUCCESS, CLEAR_APP_STATE, APP_CLOSED } from "../constants";

import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";
import auth from "./authReducer";
import profile from "./profileReducer";
import app from "./appReducer";
import permissions from "./permissionReducer";
import realestate from "./realEstateReducer";

import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
	sidebar,
	layout,
	theme,
	auth,
	profile,
	app,
	permissions,
	realestate,
});

const rootReducer = (state, action) => {
	if (action.type === LOGOUT_USER_SUCCESS) {
		// Clear the persisted state
		// On logout remove stored token if any and persisted userid
		storage.removeItem("auth");
		localStorage.removeItem("token");
		localStorage.removeItem("userid");

		// Pull out the state we do not want to clear before reassigning state
		const { theme, layout, sidebar } = state;

		state = { theme, layout, sidebar };
	} else if (action.type === CLEAR_APP_STATE) {
		// Clear all persisted state
		storage.removeItem("auth");
		storage.removeItem("theme");
		storage.removeItem("app");

		localStorage.removeItem("token");
		localStorage.removeItem("userid");

		state = {};
	} else if (action.type === APP_CLOSED) {
		// If the browser window is closed remove persisted auth state
		storage.removeItem("auth");
	}

	return appReducer(state, action);
};

export default rootReducer;
