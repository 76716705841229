import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class Landing extends React.Component {
	render() {
		return <Redirect to="/app" />;
	}
}

export default connect()(Landing);
