import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert as ReactStrapAlert } from "reactstrap";

import { connect } from "react-redux";
import { dismissAlert } from "../../redux/actions/appActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

import ProgressBar from "../ProgressBar";

import _ from "lodash";

export class Alert extends Component {
	constructor(props) {
		super(props);

		this.state = {
			timeoutRef: {},
			timeoutOccured: {},
		};

		this.MAX_ALERTS = 3;
	}

	static propTypes = {
		alerts: PropTypes.array,
		dismissAlert: PropTypes.func,
	};

	validTypeReceived = (type) => {
		switch (type) {
			case "success":
				return true;
			case "primary":
				return true;
			case "warning":
				return true;
			case "danger":
				return true;
			default:
				return false;
		}
	};

	getIconByType = (type) => {
		switch (type) {
			case "success":
				return (
					<FontAwesomeIcon
						className="py-auto my-auto"
						icon={faBell}
						fixedWidth
					/>
				);
			case "primary":
				return (
					<FontAwesomeIcon
						className="py-auto my-auto"
						icon={faBell}
						fixedWidth
					/>
				);
			case "warning":
				return (
					<FontAwesomeIcon
						className="py-auto my-auto"
						icon={faExclamation}
						fixedWidth
					/>
				);
			case "danger":
				return (
					<FontAwesomeIcon
						className="py-auto my-auto"
						icon={faExclamation}
						fixedWidth
					/>
				);
			default:
				return null;
		}
	};

	dismiss = (id) => {
		if (!this.state.timeoutOccured[id]) {
			clearTimeout(this.state.timeoutRef[id]);
		}
		this.props.dismissAlert(id);
	};

	componentDidUpdate() {
		// Enforce max allowable alerts
		if (this.props.alerts.length > this.MAX_ALERTS) {
			var alertToBeDismissed = _.first(this.props.alerts);

			this.props.dismissAlert(alertToBeDismissed.id);
			return;
		}

		var currentTimeoutRef = this.state.timeoutRef;
		var timeoutsUpdated = false;

		this.props.alerts.forEach((alert) => {
			// Add alert
			if (alert.timeout && !this.state.timeoutRef[alert.id]) {
				var ref = setTimeout(() => {
					var currentTimeoutOccured = this.state.timeoutOccured;
					currentTimeoutOccured[alert.id] = true;

					this.setState({ timeoutOccured: currentTimeoutOccured });
					this.props.dismissAlert(alert.id);
				}, alert.timeout);

				currentTimeoutRef[alert.id] = ref;
				timeoutsUpdated = true;
			}
		});

		if (timeoutsUpdated) {
			this.setState({ timeoutRef: currentTimeoutRef });
		}
	}

	render() {
		return (
			<div className="custom-alert-container">
				{this.props.alerts.map((alert) => {
					return (
						<ReactStrapAlert
							isOpen={true}
							toggle={() => {
								this.dismiss(alert.id);
							}}
							color={alert.type}
							className="shadow-lg alert-outline-coloured"
							key={alert.id}
						>
							<div className="alert-icon my-auto">
								{this.getIconByType(alert.type)}
							</div>
							<div className="alert-message pb-0">
								<strong>{alert.text}</strong>
								<ProgressBar
									duration={alert.timeout}
									color={alert.type}
									customSize="xs"
									className="mt-2 p-0"
								/>
							</div>
						</ReactStrapAlert>
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	alerts: state.app.alerts,
});

export default connect(mapStateToProps, { dismissAlert })(Alert);
