import * as types from "../constants";

import { toggleTheme } from "../../services/themeService";

export const enableLightTheme = () => {
	toggleTheme("light");

	return {
		type: types.THEME_TOGGLE,
		payload: "light",
	};
};

export const enableDarkTheme = () => {
	toggleTheme("dark");

	return {
		type: types.THEME_TOGGLE,
		payload: "dark",
	};
};
