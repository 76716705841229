import axios from "axios";
import Cookies from "js-cookie";

import {
	USER_LOADED,
	USER_LOADING,
	AUTH_ERROR,
	SAVE_USER_AUTH_STATUS,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAILURE,
	LOGOUT_USER_SUCCESS
} from "../constants";

import { alerter } from "../../utilities/alerts";
import { tokenConfig } from "../helpers";

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
	// User Loading
	dispatch({ type: USER_LOADING });

	var userid = getState().auth.userid;

	axios
		.get(`/api/v1/users/${userid}/`, tokenConfig(getState))
		.then((res) => {
			dispatch({
				type: USER_LOADED,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch({
				type: AUTH_ERROR,
			});
		});
};

// LOGIN USER
export const login = (email, password, saveToken) => (dispatch) => {
	const csrfToken = Cookies.get("csrftoken");
	// Headers
	const config = {
		headers: {
			"Content-Type": "application/json",
			"X-CSRFToken": csrfToken,
		},
	};

	// Request Body
	const body = JSON.stringify({ email: email, password: password });

	axios
		.post("/api/v1/auth/login/", body, config)
		.then((res) => {
			dispatch({
				type: LOGIN_USER_SUCCESS,
				payload: res.data,
			});
			if (saveToken) {
				dispatch({
					type: SAVE_USER_AUTH_STATUS,
					payload: res.data,
				});
			}
			if (res.data.reactivated) {
				alerter.success("Welcome back! Account has been reactivated.");
			} else {
				alerter.success("Welcome back!");
			}
		})
		.catch((err) => {
			dispatch({
				type: LOGIN_USER_FAILURE,
			});
			alerter.error("An error occured while logging you in!");
		});
};

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
	axios
		.post("/api/v1/auth/logout/", null, tokenConfig(getState))
		.then((res) => {
			dispatch({
				type: LOGOUT_USER_SUCCESS,
			});
			alerter.success("Logout Successful");
		})
		.catch((err) => {
			alerter.error("Logout Failed");
		});
};
