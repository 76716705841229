import React, { Component } from "react";

import { Card, CardBody, CardHeader } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/free-regular-svg-icons";

import { alerter } from "../utilities/alerts";

export class PermissionDenied extends Component {
	componentDidMount() {
		alerter.error("Permission Denied");
	}

	render() {
		return (
			<Card>
				<CardHeader>
					<strong>Page: {window.location.href}</strong>
				</CardHeader>
				<hr className="mt-0" />
				<CardBody className="d-flex flex-column">
					<div className="justify-content-center align-self-center w-100 text-center ">
						<h1>
							<FontAwesomeIcon
								icon={faFrown}
								className="text-danger"
							/>
						</h1>
					</div>
					<div className="justify-content-center align-self-center w-100 text-center">
						<h4>
							You Do Not Have Permission To View This Content!
						</h4>
					</div>
					<div className="mt-3 justify-content-center align-self-center w-100 text-center card-subtitle text-muted">
						Please check with your organization's admin for access.
					</div>
				</CardBody>
			</Card>
		);
	}
}

export default PermissionDenied;
