import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { Button } from "reactstrap";

export class Page404 extends Component {
	constructor(props) {
		super(props);
		try {
			var referrer = new URL(document.referrer).pathname;
		} catch (_) {
			if (props.fromApp) {
				referrer = "/app";
			} else {
				referrer = "/";
			}
		}

		this.state = {
			prevPath: referrer,
		};
	}
	static defaultProps = {};

	static propTypes = {
		fromApp: PropTypes.bool.isRequired,
	};

	cleanPrevPath = () => {
		if (this.props.location.pathname === this.state.prevPath) {
			if (this.props.fromApp) {
				return "/app";
			} else {
				return "/";
			}
		} else {
			return this.state.prevPath;
		}
	};

	render() {
		return (
			<div className="text-center">
				<h1 className="display-1 font-weight-bold">404</h1>
				<p className="h1">Page not found.</p>
				<p className="h2 font-weight-normal mt-3 mb-4">
					The page you are looking for might have been removed.
				</p>
				<Link to={this.cleanPrevPath()}>
					<Button color="primary" size="lg">
						Return to website
					</Button>
				</Link>
			</div>
		);
	}
}

export default Page404;
