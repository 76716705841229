import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import {
	landing as landingRoutes,
	dashboard as dashboardRoutes,
	page as pageRoutes,
} from "./index";

import DashboardLayout from "../layouts/AppLayout";
import LandingLayout from "../layouts/LandingLayout";
import AuthLayout from "../layouts/AuthLayout";
import Page404 from "../pages/auth/Page404";
import Loading from "../components/Loader";

import ScrollToTop from "../components/ScrollToTop";

import WithPermissions from "./WithPermissions";

const childRoutes = (Layout, routes, auth) =>
	routes.map(
		(
			{ children, path, privateRoute, component: Component, access },
			index
		) =>
			children ? (
				// Route item with children
				children.map(
					(
						{
							path,
							childPrivateRoute,
							component: Component,
							access,
						},
						index
					) => (
						<Route
							key={index}
							path={path}
							exact
							render={(props) => {
								if (childPrivateRoute) {
									if (auth.isLoading) {
										return (
											<AuthLayout>
												<Loading />
											</AuthLayout>
										);
									} else if (!auth.isAuthenticated) {
										return <Redirect to="/app/login" />;
									} else {
										const RouteComponent = WithPermissions(
											Component,
											Layout
										);
										return (
											<RouteComponent
												passThroughProps={props}
												checkAccess={access}
											/>
										);
									}
								} else {
									return (
										<Layout>
											<Component {...props} />
										</Layout>
									);
								}
							}}
						/>
					)
				)
			) : (
				// Route item without children
				<Route
					key={index}
					path={path}
					exact
					render={(props) => {
						if (privateRoute) {
							if (auth.isLoading) {
								return (
									<AuthLayout>
										<Loading />
									</AuthLayout>
								);
							} else if (!auth.isAuthenticated) {
								return <Redirect to="/app/login" />;
							} else {
								const RouteComponent = WithPermissions(
									Component,
									Layout
								);
								return (
									<RouteComponent
										passThroughProps={props}
										checkAccess={access}
									/>
								);
							}
						} else {
							return (
								<Layout>
									<Component {...props} />
								</Layout>
							);
						}
					}}
				/>
			)
	);

export class Routes extends Component {
	static propTypes = {
		auth: PropTypes.object.isRequired,
	};

	render() {
		return (
			<Router>
				<ScrollToTop>
					<Switch>
						{childRoutes(
							LandingLayout,
							landingRoutes,
							this.props.auth
						)}
						{childRoutes(
							DashboardLayout,
							dashboardRoutes,
							this.props.auth
						)}
						{childRoutes(AuthLayout, pageRoutes, this.props.auth)}
						<Route
							render={(props) => (
								<AuthLayout>
									<Page404
										location={props.location}
										fromApp={
											this.props.auth.isAuthenticated
										}
									/>
								</AuthLayout>
							)}
						/>
					</Switch>
				</ScrollToTop>
			</Router>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(Routes);
