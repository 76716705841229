import { GET_PERMISSIONS_FAILURE, GET_PERMISSIONS_SUCCESS } from "../constants";

import { isProductionEnvironment } from "../../utilities/helpers";

const initialState = {
	primaryPermission: null,
	secondaryPermissions: null,
	lastUpdated: null,
};

const initialStateDEV = {
	primaryPermission: null,
	secondaryPermissions: null,
	lastUpdated: null,
};

const returnInitialState = () => {
	if (isProductionEnvironment()) {
		return initialState;
	} else {
		return initialStateDEV;
	}
};

export default function (state = returnInitialState(), action) {
	switch (action.type) {
		case GET_PERMISSIONS_SUCCESS:
			return {
				...state,
				primaryPermission: action.payload.primary,
				secondaryPermissions: action.payload.secondary,
				lastUpdated: Date.now(),
			};
		case GET_PERMISSIONS_FAILURE:
		default:
			return state;
	}
}
