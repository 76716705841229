import store from "../redux/store";
import {
	enableDarkTheme,
	enableLightTheme,
} from "../redux/actions/themeActions";

function isProductionEnvironment() {
	if (process.env.NODE_ENV === "production") {
		return true;
	} else {
		return false;
	}
}

function loadCurrentTheme() {
	var darkMode = store.getState().theme.darkMode;

	if (darkMode) {
		store.dispatch(enableDarkTheme());
	} else {
		store.dispatch(enableLightTheme());
	}
}

function isMobileView(size) {
	return size < 768;
}

export { isProductionEnvironment, loadCurrentTheme, isMobileView };
