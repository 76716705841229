import React from "react";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer/Footer";

import Loader from "../components/Loader";
import Background from "../components/Background";

const Dashboard = ({ children }) => {
	return (
		<React.Fragment>
			<Wrapper>
				<Sidebar />
				<Main>
					<Navbar />
					<Loader />
					<Background />
					<Content>{children}</Content>
					<Footer />
				</Main>
			</Wrapper>
		</React.Fragment>
	);
};

export default Dashboard;
