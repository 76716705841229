import React, { Component } from "react";
import PropTypes from "prop-types";

import { Progress } from "reactstrap";

const UPDATE_INTERVAL = 100;

export class ProgressBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.duration ? props.duration - UPDATE_INTERVAL : 0,
			intervalRef: null,
		};
	}
	static propTypes = {
		duration: PropTypes.number,
	};

	componentDidUpdate() {
		if (this.props.duration && this.state.intervalRef === null) {
			this.setState({ value: this.state.value - UPDATE_INTERVAL });
			var ref = setInterval(() => {
				this.setState({ value: this.state.value - UPDATE_INTERVAL });
			}, UPDATE_INTERVAL);
			this.setState({ intervalRef: ref });
		}
	}

	componentWillUnmount() {
		clearInterval(this.state.intervalRef);
	}

	render() {
		let { customSize, labelText, className, ...attrs } = this.props;
		return (
			<div className={`${className}`}>
				{labelText ? <div>{labelText}</div> : null}
				<Progress
					{...attrs}
					value={this.state.value}
					min={0}
					max={this.props.duration}
					className={`custom-progress-${customSize}`}
					barClassName={`custom-progress-bar-${customSize}`}
				/>
			</div>
		);
	}
}

export default ProgressBar;
