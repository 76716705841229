import {
	PROFILE_INFO_UPDATE_FAILURE,
	PROFILE_INFO_UPDATE_SUCCESS,
	GET_PROFILE_SUCCESS,
	GET_PROFILE_FAILURE,
} from "../constants";

import { isProductionEnvironment } from "../../utilities/helpers";

const initialState = {
	address: null,
	address2: null,
	city: null,
	state: null,
	country: null,
	zipcode: null,
	emailNotifications: true,
};

const initialStateDEV = {
	address: "123 ABC Street",
	address2: "",
	city: "Austin",
	state: "Texas",
	country: "US",
	zipcode: "12345",
	emailNotifications: true,
};

const returnInitialState = () => {
	if (isProductionEnvironment()) {
		return initialState;
	} else {
		return initialStateDEV;
	}
};

export default function (state = returnInitialState(), action) {
	switch (action.type) {
		case PROFILE_INFO_UPDATE_SUCCESS:
			return {
				...state,
				address: action.payload.address,
				address2: action.payload.address2,
				city: action.payload.city,
				state: action.payload.state,
				country: action.payload.country,
				zipcode: action.payload.zipcode,
			};

		case GET_PROFILE_SUCCESS:
			return {
				...state,
				address: action.payload.address,
				address2: action.payload.address2,
				city: action.payload.city,
				state: action.payload.state,
				country: action.payload.country,
				zipcode: action.payload.zipcode,
			};
		case GET_PROFILE_FAILURE:
		case PROFILE_INFO_UPDATE_FAILURE:
		default:
			return state;
	}
}
