import Cookies from "js-cookie";

// Setup config with token - helper function
export const tokenConfig = (getState) => {
	// Get token from state
	const token = getState().auth.token;

	// Headers
	const csrfToken = Cookies.get("csrftoken");
	const config = {
		headers: {
			"Content-Type": "application/json",
			"X-CSRFToken": csrfToken,
		},
	};

	// If token, add to headers config
	if (token) {
		config.headers["Authorization"] = `Token ${token}`;
	}

	return config;
};
