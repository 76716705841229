import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleSidebar } from "../../redux/actions/sidebarActions";
import { logout } from "../../redux/actions/authActions";

import {
	enableDarkTheme,
	enableLightTheme,
} from "../../redux/actions/themeActions";

import {
	Collapse,
	Navbar,
	Nav,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	Button,
} from "reactstrap";

import { Settings, Moon, Sun } from "react-feather";

import { NavbarNotifications } from "../Notifications";

export class NavbarComponent extends Component {
	static propTypes = {
		firstname: PropTypes.string.isRequired,
		lastname: PropTypes.string.isRequired,
	};

	switchMode = () => {
		if (this.props.darkMode === true) {
			this.props.enableLightTheme();
		} else {
			this.props.enableDarkTheme();
		}
	};

	render() {
		return (
			<Navbar
				color="white"
				light
				expand
				className="navbar-always-available"
			>
				<span
					className="sidebar-toggle d-flex mr-2"
					onClick={() => {
						this.props.toggleSidebar();
					}}
				>
					<i className="hamburger align-self-center" />
				</span>

				<Collapse navbar>
					<Nav className="ml-auto" navbar>
						<NavLink onClick={this.switchMode}>
							{this.props.darkMode ? <Sun /> : <Moon />}
						</NavLink>
						<NavbarNotifications />
						{this.props.isMobile ? null : (
							<UncontrolledDropdown nav inNavbar>
								<span className="d-inline-block d-sm-none">
									<DropdownToggle nav caret>
										<Settings
											size={18}
											className="align-middle"
										/>
									</DropdownToggle>
								</span>
								<span className="d-none d-sm-inline-block">
									<DropdownToggle nav caret>
										<span className="text-dark">
											{this.props.firstname}{" "}
											{this.props.lastname}
										</span>
									</DropdownToggle>
								</span>
								<DropdownMenu right>
									<Button
										onClick={this.props.logout}
										color="danger"
										className="d-flex mx-auto"
									>
										Sign out
									</Button>
								</DropdownMenu>
							</UncontrolledDropdown>
						)}
					</Nav>
				</Collapse>
			</Navbar>
		);
	}
}

const mapStateToProps = (state) => ({
	userid: state.auth.userid,
	firstname: state.auth.firstname,
	lastname: state.auth.lastname,
	darkMode: state.theme.darkMode,
	isMobile: state.app.isMobile,
});

export default connect(mapStateToProps, {
	toggleSidebar,
	logout,
	enableDarkTheme,
	enableLightTheme,
})(NavbarComponent);
