import axios from "axios";
import { tokenConfig } from "../helpers";

import { GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE } from "../constants";

// GET USER PROFILE
export const getProfile = () => (dispatch, getState) => {
	var userid = getState().auth.userid;

	axios
		.get(`/api/v1/userprofiles/${userid}/`, tokenConfig(getState))
		.then((res) => {
			dispatch({
				type: GET_PROFILE_SUCCESS,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch({
				type: GET_PROFILE_FAILURE,
			});
		});
};
