import React, { Component } from "react";
import PropTypes from "prop-types";

import { Row, Spinner } from "reactstrap";
import { connect } from "react-redux";

export class Loader extends Component {
	static propTypes = {
		isLoading: PropTypes.bool.isRequired,
	};

	render() {
		return (
			<div
				className={`custom-loader-container ${
					this.props.isLoading ? "" : "d-none"
				}`}
			>
				<div className="custom-loader-spinner">
					<Row className="justify-content-center align-self-center w-100 text-center">
						<Spinner className="custom-spinner-design" />
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	isLoading: state.app.isLoading,
});

export default connect(mapStateToProps)(Loader);
